import * as React from "react"
import { Helmet } from "react-helmet"
import "../css/reset.scss"
import "../css/styles.scss"

// markup
const IndexPage = () => {
  return (
    <div className="wrap">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Terri Turns 30</title>
          <link rel="canonical" href="https://territurns30.co.uk" />
        </Helmet>
    <header>
  <svg width="250" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
     viewBox="81 196.3 385.3 301.2" enable-background="new 81 196.3 385.3 301.2">
  <g>
    <rect x="172.9" y="237.6" fill="#8F00FD" width="31" height="10.3"/>
    <rect x="243.2" y="227.3" fill="#8F00FD" width="10.3" height="10.3"/>
    <rect x="303.1" y="227.3" fill="#8F00FD" width="10.3" height="10.3"/>
    <polygon fill="#8F00FD" points="183.2,367.7 214.2,367.7 214.2,326.4 193.6,326.4 193.6,316.1 172.9,316.1 172.9,326.4 
      183.2,326.4   "/>
    <rect x="253.5" y="326.4" fill="#8F00FD" width="10.3" height="10.3"/>
    <path fill="#8F00FD" d="M466.3,347.1v-20.6H456v-10.3h-51.6v10.3h41.3v10.3h-41.3v10.3h31v10.3h10.3v10.3h-10.3V378h-41.3v-10.3
      h-8.3v-10.3h8.3v10.3h41.3v-10.3h-31v-10.3h-10.3v-10.3h-8.3h-2h-8.3v-10.3h-10.3v-10.3h-51.6v10.3h41.3v10.3h10.3V378h-10.3v-41.3
      h-31V378h-10.3v-51.6h-8.3h-2H295v-10.3h-31v10.3h20.6v10.3H264V378h-10.3v-41.3h-8.3v-10.3h-10.3v-10.3h-20.6v10.3h10.3v41.3
      h-10.3V378h-31v-10.3h-10.3v-41.3h-9.3v-20.6h-10.3v-10.3h-10.3v-57.8h9.3V279h10.3v10.3h10.3v10.3h61.9v-10.3h8.3v10.3h31V248H293
      v41.3h10.3v10.3h31V248h18.6v41.3h10.3v10.3h31v-92.9h-10.3v-10.3h-20.6v10.3h10.3V217h-10.3v-10.3H353v20.6h-8.3V217h-31v10.3
      h20.6v10.3h-20.6v41.3h-10.3v-41.3h-8.3v-10.3h-10.3V217h-31v10.3h20.6v10.3h-20.6v41.3h-10.3v-41.3h-8.3h-2h-8.3v-10.3h-10.3V217
      h-41.3v10.3h31v10.3h10.3v20.6h-41.3v10.3h41.3v10.3h-41.3v-10.3H163v-31h1h9.3v-10.3H164v-20.6h-10.3v-10.3H91.3v10.3h51.6V217
      h-20.6v61.9H112V217H91.3v-10.3H81v20.6h10.3v10.3h10.3v51.6H112v6.2H91.3v10.3h51.6V316h-20.6v62H112v-61.9H91.3v-10.3H81v20.6
      h10.3v10.3h10.3v51.6H112v6.2H91.3v10.3h41.3v10.3h10.3v20.6h-10.3V446h10.3v20.6h-10.3v10.3H91.3v-10.3H81v20.6h10.3v10.3h51.6
      v-10.3h10.3v-10.3h9.3h1h9.3v10.3h10.3v10.3h51.6v-10.3H245v-72.3h-10.3v-10.3h-10.3v-6.2h10.3v-10.3h8.3h2h8.3v10.3h31v-51.6h18.6
      v41.3h10.3v10.3h20.6v-10.3h20.6v10.3h31v-10.3h8.3v10.3h51.6v-10.3H456V378h10.3v-20.6H456v-10.3H466.3z M363.1,227.3h10.3v51.6
      h-10.3V227.3z M162.6,446.2h-9.3v-10.3h9.3V446.2z M224.5,466.8h-10.3v10.3h-31v-10.3h-10.3v-51.6h-9.3h-1h-9.3v-10.3H143v-6.2
      v-4.1v-57.8h19.6V378h10.3v10.3h10.3v6.2v4.1v6.2h31v10.3h10.3L224.5,466.8L224.5,466.8z"/>
    <rect x="394.1" y="326.4" fill="#8F00FD" width="10.3" height="10.3"/>
    <polygon fill="#8F00FD" points="132.6,446.2 112,446.2 112,435.9 132.6,435.9 132.6,415.2 91.3,415.2 91.3,404.9 81,404.9 
      81,425.5 91.3,425.5 91.3,435.9 101.7,435.9 101.7,456.5 91.3,456.5 91.3,466.8 132.6,466.8  "/>
    <path fill="#8F00FD" d="M183.2,415.2v51.6h31v-51.6H183.2z M203.9,446.2h-10.3v-10.3h10.3V446.2z"/>
    <rect x="172.9" y="404.9" fill="#8F00FD" width="10.3" height="10.3"/>
  </g>
  </svg>
</header>
<div className="flip-clock-wrapper-wrap">
    <h2>Terri Has Turned 30!</h2>
    <h3>And to celebrate she got:</h3>
</div>


<section id="cd-timeline" className="cd-container">
    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-1">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#1</h2>
        <p>This very website, dedicated to your thirtieth.</p>
        <span className="cd-date">1985</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-2">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#2</h2>
        <p>Some Hummingbird Bakery cupcakes to sweeten your day at work.</p>
        <span className="cd-date">1986</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-3">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#3</h2>
        <p>30 red roses.</p>
        <span className="cd-date">1987</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-4">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#4</h2>
        <p>30 "30" balloons.</p>
        <span className="cd-date">1988</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-5">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#5</h2>
        <p>The big 3-0 balloons.</p>
        <span className="cd-date">1989</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-6">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#6</h2>
        <p>A Moomin cake mould and cake made by me.</p>
        <span className="cd-date">1990</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-7">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#7</h2>
        <p>A "30" candle.</p>
        <span className="cd-date">1991</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-8">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#8</h2>
        <p>A 30 card with 30 kisses...</p>
        <span className="cd-date">1992</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-9">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#9</h2>
        <p>...and £30 in it.</p>
        <span className="cd-date">1993</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-10">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#10</h2>
        <p>Number ones from all your birthdays. <a href="http://open.spotify.com/user/bigaluk1980/playlist/4VOg2l9IVJkpdoxgeNmnyU">Spotify Playlist</a>.</p>
        <small>They're not all the exact track, but they are the right songs.</small>
        <span className="cd-date">1994</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-11">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#11</h2>
        <p>On 30th April, we're having a meal on the the 30<sup>(+1)</sup> Floor of The Shard.</p>
        <span className="cd-date">1995</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-12">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#12</h2>
        <p>Spoiling you now... 30 Ferrero Rocher.</p>
        <span className="cd-date">1996</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-13">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#13</h2>
        <p>Hama bead creation, with maths.</p>
        <span className="cd-date">1997</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-14">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#14</h2>
        <p>A moomin mug.</p>
        <span className="cd-date">1998</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-15">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#15</h2>
        <p>30ml of 212 Sexy to try, sexy.</p>
        <span className="cd-date">1999</span>
      </div> 
    </div> 


    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-17">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#16</h2>
        <p>A zinc frame and piccy of us. <a href="https://www.google.co.uk/webhp?sourceid=chrome-instant&ion=1&espv=2&ie=UTF-8#q=zinc%20atomic%20number">Why zinc?</a>.</p>
        <span className="cd-date">2001</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-18">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#17</h2>
        <p>£30 Pandora Voucher towards those rings you want.</p>
        <span className="cd-date">2002</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-19">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#18</h2>
        <p>£30 Lady V Voucher, towards a new dress.</p>
        <span className="cd-date">2003</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-20">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#19</h2>
        <p>£30 Bravissimo voucher.</p>
        <span className="cd-date">2004</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-21">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#20</h2>
        <p>£30 Cath Kidston Voucher.</p>
        <span className="cd-date">2005</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-22">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#21</h2>
        <p>30 £100,000 scratchcards.</p>
        <span className="cd-date">2006</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-23">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#22</h2>
        <p>30 minute head, neck & back massage at Vidatherapy, Teddington.</p>
        <span className="cd-date">2007</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-24">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#23</h2>
        <p>Numeri romani.</p>
        <span className="cd-date">2008</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-25">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#24</h2>
        <p>Laurent Perrier Rosé.</p>
        <span className="cd-date">2009</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-26">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#25</h2>
        <p>Daily Mail from 11 April 1985.</p>
        <span className="cd-date">2010</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-27">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#26</h2>
        <p>A 3D Printed "Terri Turns 30".</p>
        <span className="cd-date">2011</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-28">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#27</h2>
        <p>30 1 pence coins dated from 1985 to 2014 incased in clear resin (but didn't quite go to plan).</p>
        <span className="cd-date">2012</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-29">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#28</h2>
        <p>Tiffany Bracelet replacement. Same range as your necklace & earrings, 3 x 0.3 carat diamonds.  All the 3s.</p>
        <span className="cd-date">2013</span>
      </div> 
    </div> 

     <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-29">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#29</h2>
        <p>2 nights in Mallorca for your birthday weekend.</p>
        <span className="cd-date">2013</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-30">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#30</h2>
        <p>A Mickey & Minnie purse with 30<sup>(x10)</sup> US Dollars...</p>
        <span className="cd-date">2014</span>
      </div> 
    </div> 

    <div className="cd-timeline-block">
      <div className="cd-timeline-img cd-31">
        
      </div> 

      <div className="cd-timeline-content">
        <h2>#31 (bonus round)</h2>
        <p>...because taking every year since your birth totals thirty-one... So in 31 days from your birthday we're going to DisneyWorld, Florida, for a week (while Grace, etc. are out there).</p>
        <span className="cd-date">2015</span>
      </div> 
    </div> 
  </section>
  </div>
  )
}

export default IndexPage
